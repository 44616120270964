<script setup lang='ts'>

</script>
    
<template>
    <RouterLink to="/" class="cursor-pointer flex items-center">
        <img src="/logo.png" class="size-[54px]" alt="">

        <div class="font-medium text-[20px] ml-[12px]">设计咨询AI辅助云平台</div>
    </RouterLink>
</template>
