import { createApp } from 'vue'
import { useMessage } from 'naive-ui'
import '@/styles/lib/viewer.css'
import VueViewer from 'v-viewer'
import App from './App.vue'
import { setupI18n } from './locales'
import { setupAssets, setupScrollbarStyle } from './plugins'
import { setupStore } from './store'
import { setupRouter } from './router'
import { MotionPlugin } from "@vueuse/motion";
import { VueQueryPlugin } from '@tanstack/vue-query'


import '@/styles/font.less'
import '@/styles/transition.less'
import '@/styles/notice.less'
import 'vue-waterfall-plugin-next/dist/style.css'


window.$message = useMessage()

async function bootstrap() {
	const app = createApp(App)
	app.use(VueViewer)
	app.use(MotionPlugin)
	setupAssets()
	setupScrollbarStyle()
	setupStore(app)
	setupI18n(app)
	await setupRouter(app)
	app.use(VueQueryPlugin)
	app.mount('#app')
}

bootstrap()
