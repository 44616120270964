<script setup lang='ts'>
import { useBasicLayout } from '@/hooks/useBasicLayout'
import Header from "./header/index.vue"

const { isMobile } = useBasicLayout()

const getMobileMainClass = computed(() => {
  if (isMobile.value)
    return ['rounded-none', 'shadow-none']
  return ['dark:border-neutral-800']
})

const getMobileLayoutClass = computed(() => {
  if (isMobile.value)
    return ['flex-col']
  return ['dark:border-neutral-800']
})
</script>
    
<template>
  <div class="h-full dark:bg-[#24272e] transition-all p-0">
    <div class="h-full overflow-hidden">
      <div class="z-40 transition flex h-full relative flex-col" :class="getMobileLayoutClass">
        <Header />

        <NLayoutContent class="h-full" style="flex: 1" :class="getMobileMainClass">
          <RouterView v-slot="{ Component, route }">
            <component :is="Component" :key="route.fullPath" />
          </RouterView>
        </NLayoutContent>
      </div>
    </div>
  </div>
</template>
