<script setup lang='ts'>
import { SvgIcon } from '@/components/common'
import { NButton, } from 'naive-ui'
import { useAuthStore, } from '@/store'

import defaultAvatar from '@/assets/avatar_old.png'

const router = useRouter()
const authStore = useAuthStore()

const username = ref(authStore.userInfo.username ?? '未登录')

function logOut() {
    authStore.logOut()
    // router.push("/")

    // 跳出到登录页
    router.push('/login')
}

function renderLogout() {
    return h(
        NButton, {
        tertiary: true,
        type: "error",
    },
        { default: () => '退出登录' }
    )
}

const options = [
    {
        key: 'user-center',
        label: "个人中心",
        props: {
            onClick: () => {
                router.push({ name: 'UserCenter' })
            }
        }
    },
    {
        key: 'header-divider',
        type: 'divider'
    },
    {
        label: '退出登录',
        key: 'logout',
        type: 'render',
        render: renderLogout,
        props: {
            onClick: logOut
        }
    },
]
</script>
    
<template>
    <div class="w-[240px] h-[64px] flex rounded-full items-center justify-center p-[8px] bg-white shadow-lg">
        
        <NDropdown trigger="click" :options="options" >
            <div class="flex items-center cursor-pointer">
                <NAvatar :size="48" :src="defaultAvatar" style="border-radius: 50%;" />
                <span class="ml-3 ellipse">{{ username }}</span>
                <SvgIcon class="text-[24px] ml-1 text-black/40" icon="ri:arrow-down-s-line" />
            </div>
        </NDropdown>    
        
        <div class="ml-6 size-[48px] rounded-full border-2 border-solid border-black/10 bg-black/[0.02] flex items-center justify-center cursor-pointer group hover:border-blue-300 transition">
            <SvgIcon class="text-[24px] text-black/60 group-hover:text-blue-500 transition" icon="ri:settings-4-line" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50px;
}
</style>
